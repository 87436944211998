import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import LogoDesktop from "../assets/img/matchduck_font-duck-trans.png";
import LogoMobile from "../assets/img/MatchDuck New Logo transparent.png";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Nav() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [toastId, setToastId] = useState(null); // Neu hinzugefügt

    useEffect(() => {
        if (isMenuOpen) {
            // Scrollen deaktivieren
            document.body.style.overflow = 'hidden';
        } else {
            // Scrollen aktivieren
            document.body.style.overflow = '';
            // Toast schließen, wenn Menü geschlossen wird
            if (toastId !== null) {
                toast.dismiss(toastId);
                setToastId(null);
            }
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isMenuOpen, toastId]); // Abhängigkeit hinzugefügt

    const notify = () =>
        toast.info('🚀 Wir arbeiten dran! Komm später nochmal!', {
            position: "top-right",
            icon: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            transition: Bounce,
        });

    const notify2 = () => {
        // Toast-ID speichern
        const id = toast.info('🚀 Wir arbeiten dran! Komm später nochmal!', {
            position: "bottom-center",
            icon: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            transition: Bounce,
            closeButton: isMenuOpen,
        });
        setToastId(id);
    };

    return (
        <nav className="absolute top-0 w-full flex justify-between items-center p-4 bg-transparent z-50">
            {/* Logo */}
            <ToastContainer/>
            <div className="flex items-center">
                {/* Desktop Logo */}
                <img src={LogoDesktop} className="h-20 hidden sm:block" alt="Desktop Logo"/>
                {/* Mobile Logo */}
                <img src={LogoMobile} className="h-20 w-auto sm:hidden" alt="Mobile Logo"/>
            </div>
            {/* Navigation Links und Hamburger-Menü */}
            <div className="flex items-center ml-auto">  {/* ml-auto added */}
                {/* Desktop Navigation Links */}
                <div className="hidden sm:flex space-x-4">
                    <button
                        onClick={notify}
                        className="bg-[#4C1D95] text-zinc-200 font-semibold py-2 px-4 rounded-full hover:bg-pink-600 hover:text-white transition duration-300"
                    >
                        Login
                    </button>

                    <button
                        onClick={notify}
                        className="bg-pink-600 text-white font-semibold py-2 px-4 rounded-full hover:bg-white hover:text-[#4C1D95] transition duration-300"
                    >
                        Sign Up
                    </button>
                </div>
                {/* Hamburger-Menü Button (nur Mobil) */}
                <div className="sm:hidden ml-2">
                    <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {/* Hamburger Icon */}
                        <svg className="w-8 h-8 text-pink-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            {!isMenuOpen ? (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            ) : (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            )}
                        </svg>
                    </button>
                </div>
            </div>
            {/* Mobile Navigation Links */}
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        key="mobileMenu"
                        initial={{y: '-100%'}}
                        animate={{y: 0}}
                        exit={{y: '-100%'}}
                        transition={{duration: 0.3}}
                        className="fixed top-0 left-0 w-full h-full bg-[#2C2B59] sm:hidden flex flex-col items-center justify-center z-40"
                    >
                        {/* Schließen-Button innerhalb des mobilen Menüs */}
                        <img alt="Mobil Logo" src={LogoMobile} className="w-2/3"/>
                        <button
                            onClick={() => setIsMenuOpen(false)}
                            className="absolute top-4 right-4 text-pink-600"
                        >
                            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                        <div className="flex flex-col items-center p-4 space-y-4">
                            <button
                                onClick={() => {
                                    notify2();
                                }}
                                className="w-48 bg-white text-pink-600 font-semibold py-2 px-4 rounded-full border border-pink-600 hover:bg-pink-600 hover:text-white transition duration-300"
                            >
                                Login
                            </button>
                            <button
                                onClick={() => {
                                    notify2();
                                }}
                                className="w-48 bg-pink-600 text-white font-semibold py-2 px-4 rounded-full hover:bg-white hover:text-pink-600 border border-pink-600 transition duration-300"
                            >
                                Sign Up
                            </button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </nav>
    );
}
