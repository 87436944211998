import React from 'react';
import Steven from '../../assets/img/steven.png'
import Marvin from '../../assets/img/marvin.jpg'
import Merve from '../../assets/img/merve.png'

export default function AboutUs() {
    return (
        <div className="flex flex-col w-full bg-gradient-to-b from-[#1A1937] to-[#2C2B59] ">
            <div className="text-center pt-20 w-full">
                <h2 className="text-xl pb-12 text-zinc-200 text-center md:text-3xl lg:text-4xl xl:text-5xl font-bold leading-tight md:leading-none">
                    Wer wir sind?
                </h2>
            </div>
            <div className="flex flex-col gap-12 md:px-36 sm:px-12 w-full">
                <div className="flex flex-col md:px-12 px-4 sm:flex-row gap-6 items-center">
                    <div className="w-24 sm:w-36">
                        <img alt="Steven" className="rounded-full shadow-lg" src={Marvin}/>
                    </div>
                    <div className="text-zinc-200 text-center sm:text-left">
                        <h2 className="text-lg sm:text-xl font-bold">Marvin</h2>
                        <p className="text-sm sm:text-base max-w-xs sm:max-w-md">
                            Der Gründer von Cordes-Hosting dem Hoster der hinter MatchDuck steckt.
                            Fing im Alter von 15 an zu Programmieren. Verwaltet die ganze Technik hinter diesem Projekt sowie die Backend-Entwicklung.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col md:gap-6 gap-12 md:px-12 sm:flex-row-reverse items-center">
                    <div className="w-24 sm:w-36">
                        <img alt="Steven" className="rounded-full shadow-lg" src={Steven}/>
                    </div>
                    <div className="text-zinc-200 text-center sm:text-left">
                        <h2 className="text-lg sm:text-xl font-bold">Steven</h2>
                        <p className="text-sm sm:text-base max-w-xs sm:max-w-md">
                            Er war 4 Jahre lang Zeitsoldat. Nach seiner Dienstzeit kam er zu Cordes-Hosting und lernte das programmieren.
                            Er ist Hauptentwickler des Frontends der App, Webapp und der Webseite.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-6 md:px-12 items-center">
                    <div className="w-24 sm:w-36">
                        <img alt="Steven" className="rounded-full shadow-lg" src={Merve}/>
                    </div>
                    <div className="text-zinc-200 text-center sm:text-left">
                        <h2 className="text-lg sm:text-xl font-bold">Merve</h2>
                        <p className="text-sm sm:text-base max-w-xs sm:max-w-md">
                            Ich bin Therapeutin und arbeite hauptsächlich mit Kindern. Ich kümmere mich bei MatchDuck um den Support und die Qualität.
                            Das bedeutet das ich alle neuen Features teste bevor sie live gehen.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}