import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@awesome.me/kit-043ae249cf/icons/classic/brands";
import { faBrowser } from "@fortawesome/pro-duotone-svg-icons";

export default function MatchduckApp() {
    return (
        <div className="bg-[#2C2B59] flex flex-col pt-28 w-full">
            <div className="w-full">
                <h1 className="text-2xl pb-8 text-zinc-200 text-center sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-tight">
                    Wo wird unsere App verfügbar sein?
                </h1>
            </div>
            <div className="flex flex-col items-center gap-12 px-4 sm:px-12 md:px-24 lg:px-36 w-full">
                <div className="w-full flex flex-col items-center text-center">
                    <h2 className="text-xl text-zinc-200 sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-4">
                        Im Browser!
                    </h2>
                    <FontAwesomeIcon className="text-zinc-200" size="6x" icon={faBrowser} />
                </div>
                <div className="flex flex-col sm:flex-row justify-center items-center text-center gap-12 w-full">
                    <div className="w-full flex flex-col items-center text-center">
                        <h2 className="text-xl text-zinc-200 sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-4">
                            Auf iOS!
                        </h2>
                        <FontAwesomeIcon className="text-zinc-200" size="6x" icon={faApple} />
                    </div>
                    <div className="w-full flex flex-col items-center text-center">
                        <h2 className="text-xl text-zinc-200 sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-4">
                            Auf Android!
                        </h2>
                        <FontAwesomeIcon className="text-zinc-200" size="6x" icon={faAndroid} />
                    </div>
                </div>
            </div>
        </div>
    );
}
