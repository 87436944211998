import React from 'react';

export default function ReleaseDate() {
    return (
        <div className="bg-gradient-to-b from-[#2C2B59] to-[#1A1937] flex flex-col pt-24 w-full">
            <div className="w-full">
                <h1 className="text-2xl pb-8 text-zinc-200 text-center sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-tight">
                    Wann wird unsere App veröffentlicht?
                </h1>
            </div>
            <div className="flex flex-col items-center gap-12 px-4 sm:px-12 md:px-24 lg:px-36 w-full">
                <div className="w-full flex flex-col items-center text-center">
                    <h2 className="text-xl text-zinc-200 sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-4">
                        Verfügbar ab dem 1. März 2025
                    </h2>
                    {/* Optional: Fügen Sie hier ein Icon oder ein Bild hinzu */}
                </div>
            </div>
        </div>
    );
}
