import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleEuro, faPeople, faShieldCheck } from "@fortawesome/pro-duotone-svg-icons";
import PlayStore from "../../assets/img/playstore.png"
import AppStore from "../../assets/img/appstore.png"

export default function HomeHero() {
    return (
        <div className="w-full flex flex-col items-center justify-around text-zinc-200 text-base sm:text-lg md:text-xl">
            {/* Hero Section */}
            <div className="flex pt-20 sm:pt-32 md:pt-40 gap-12 sm:gap-20 md:gap-36 flex-col justify-center w-full">
                <div className="flex flex-col items-center p-4 md:p-8 text-center">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-tight sm:leading-snug md:leading-none">
                        Kein Bock mehr auf Abzocke beim Dating?
                    </h1>
                    <h1 className="mt-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-tight sm:leading-snug md:leading-none">
                        Dann bist du bei uns richtig!
                    </h1>
                </div>

                {/* Download Section */}
                <div className="flex w-full items-center justify-center flex-col">
                    <h2 className="text-lg sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold leading-tight sm:leading-snug md:leading-none text-center">
                        Jetzt downloaden!
                    </h2>
                    <div className="flex justify-center items-center flex-col md:flex-row gap-4 md:gap-8 mt-4">
                        <div className="w-3/4 md:w-1/3">
                            <img src={AppStore} alt="Appstore"/>
                        </div>
                        <div className="w-3/4 md:w-1/3">
                            <img src={PlayStore} alt="Playstore"/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Argumente Section */}
            <div className="flex mt-20 pb-8 sm:mt-28 md:mt-36 flex-col items-center justify-center w-full">
                <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center pb-8">
                    Unsere Argumente!
                </h2>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-6 w-full md:px-16 px-4">
                    {/* Argument Card 1 */}
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex flex-col justify-between bg-opacity-85 rounded-lg p-4 shadow-lg bg-[#4C1D95] w-full h-full">
                            <article className="text-center flex-grow">
                                <FontAwesomeIcon size="2x" icon={faCircleEuro}/>
                                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold mt-2">
                                    Keine Abzocke!
                                </h2>
                                <p className="text-sm sm:text-base md:text-lg lg:text-xl">
                                    Wir berechnen dir keine exorbitanten Preise.
                                </p>
                                <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-1">
                                    2 Euro im Monat ist unser Versprechen.
                                </p>
                            </article>
                        </div>
                    </div>

                    {/* Argument Card 2 */}
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex flex-col justify-between bg-opacity-85 rounded-lg p-4 shadow-lg bg-[#4C1D95] w-full h-full">
                            <article className="text-center flex-grow">
                                <FontAwesomeIcon size="2x" icon={faPeople}/>
                                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold mt-2">
                                    Ausgewogene Balance!
                                </h2>
                                <p className="text-sm sm:text-base md:text-lg lg:text-xl">
                                    Wir stellen sicher, dass jeder eine Chance hat, mit unserer 50/50 Regelung.
                                </p>
                            </article>
                        </div>
                    </div>

                    {/* Argument Card 3 */}
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex flex-col justify-between bg-opacity-85 rounded-lg p-4 shadow-lg bg-[#4C1D95] w-full h-full">
                            <article className="text-center flex-grow">
                                <FontAwesomeIcon size="2x" icon={faShieldCheck}/>
                                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold mt-2">
                                    Sicherheit!
                                </h2>
                                <p className="text-sm sm:text-base md:text-lg lg:text-xl">
                                    Neben unserem NSFW-Filter und Verifikation bleiben deine Daten DSGVO-konform in Deutschland!
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
