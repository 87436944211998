import React, {useState, useEffect} from 'react';
import Nav from './nav';
import { Outlet } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faInstagram, faFacebook} from "@awesome.me/kit-043ae249cf/icons/classic/brands";
import {faTimes} from "@fortawesome/pro-duotone-svg-icons";
import axios from 'axios'
import {Bounce, toast} from "react-toastify";

export default function Layout() {

    const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
    const [isImprintOpen, setIsImprintOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [contactForm, setContactForm] = useState({
        from: "",
        name: "",
        message: ""
    });

    const notify = (text) =>
        toast.info(text, {
            position: "top-right",
            icon: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            transition: Bounce,
        });

    const notify2 = (text) =>
        toast.error(text, {
            position: "top-right",
            icon: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            transition: Bounce,
        });

    useEffect(() => {
        if(isImprintOpen){
            document.body.style.overflow = 'hidden'
        }else{
            document.body.style.overflow = ''
        }
    }, [isImprintOpen]);

    useEffect(() => {
        if(isPrivacyOpen){
            document.body.style.overflow = 'hidden'
        }else{
            document.body.style.overflow = ''
        }
    }, [isPrivacyOpen]);

    const handleInputChange = (e) => {
        setContactForm({
            ...contactForm,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setDisabled(true)
            const response = await axios.post('https://flow.cordes-hosting.net/webhook/7498ce78-1647-433a-acc6-186be636aeda', contactForm);
            if(response.status === 200){
                setIsContactOpen(false)
                notify("Nachricht erfolgreich abgeschickt!")
            }else{
                setIsContactOpen(false)
                notify2("Nachricht fehlgeschlagen. Bitte versuche es später erneut.")
                setDisabled(false)
            }
        } catch (error) {
            setIsContactOpen(false)
            notify2("Nachricht fehlgeschlagen. Bitte versuche es später erneut.")
            setDisabled(false)
        }
    };

    return (
        <div className="flex animate-fade-in-down duration-300 flex-col min-h-screen">
            {/* Navigation */}
            <header className="shadow w-full top-0 z-50">
                <Nav/>
            </header>

            {/* Hauptinhalt mit Flex Grow */}
            <main className="pt-20 flex-grow">
                <Outlet/>
            </main>

            {/* Footer */}
            <footer className="bg-[#1A1937] shadow-lg pt-12 py-4">
                <div className="container mx-auto text-center text-gray-500">
                    © 2024 MatchDuck & Cordes-Hosting. Alle Rechte vorbehalten.
                </div>
                <div className="flex flex-col items-center text-center text-gray-500 pt-4">
                    <p className="text-lg font-semibold">Social Media</p>
                    <div className="flex justify-center gap-4 pt-2">
                        <a href="https://discord.gg/b5KAvBC3PA" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="hover:text-gray-300 duration-100" size="2x" icon={faDiscord}/>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61565684558586" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="hover:text-gray-300 duration-100" size="2x" icon={faFacebook}/>
                        </a>
                        <a href="https://www.instagram.com/matchduck.offical" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="hover:text-gray-300 duration-100" size="2x" icon={faInstagram}/>
                        </a>
                    </div>
                </div>
                {/* Rechtliches Sektion */}
                <div className="flex md:flex-row flex-col justify-center text-center text-gray-500 pt-4 space-x-4">
                    <button
                        className="underline hover:text-gray-300"
                        onClick={() => setIsPrivacyOpen(true)}
                    >
                        Datenschutzerklärung
                    </button>
                    <span className="md:flex hidden">|</span>
                    <button
                        className="underline hover:text-gray-300"
                        onClick={() => setIsImprintOpen(true)}
                    >
                        Impressum
                    </button>
                    <span className="md:flex hidden">|</span>
                    <button
                        className="underline hover:text-gray-300"
                        disabled={disabled}
                        onClick={() => setIsContactOpen(true)}
                    >
                        Kontakt
                    </button>
                </div>
                {isContactOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-[#1A1937] w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 p-6 rounded-lg overflow-y-auto max-h-screen">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl text-zinc-200 font-semibold">Kontaktformular</h2>
                                <button onClick={() => setIsContactOpen(false)}>
                                    <FontAwesomeIcon icon={faTimes} className="text-gray-700 hover:text-gray-900" />
                                </button>
                            </div>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="block text-gray-200">E-Mail Adresse</label>
                                    <input
                                        type="email"
                                        name="from"
                                        value={contactForm.from}
                                        onChange={handleInputChange}
                                        className="w-full border border-zinc-700 text-zinc-200 rounded bg-[#2C2B59] p-2"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-200">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={contactForm.name}
                                        onChange={handleInputChange}
                                        className="w-full border border-zinc-700 text-zinc-200 rounded bg-[#2C2B59] p-2"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-200">Nachricht</label>
                                    <textarea
                                        name="message"
                                        value={contactForm.message}
                                        onChange={handleInputChange}
                                        className="w-full border border-zinc-700 text-zinc-200 rounded bg-[#2C2B59] p-2"
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="bg-pink-600 text-white font-semibold py-2 px-4 rounded-full hover:bg-white hover:text-[#4C1D95] transition duration-300"
                                >
                                    Absenden
                                </button>
                            </form>
                        </div>
                    </div>
                )}
                {/* Modal für Datenschutzerklärung */}
                {isPrivacyOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div
                            className="bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 p-6 rounded-lg overflow-y-auto max-h-screen">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-semibold">Datenschutzerklärung</h2>
                                <button onClick={() => setIsPrivacyOpen(false)}>
                                    <FontAwesomeIcon icon={faTimes} className="text-gray-700 hover:text-gray-900"/>
                                </button>
                            </div>
                            <div className="text-gray-700">
                                <div className="p-4">
                                    <h1 className="text-2xl font-semibold mb-4">Datenschutz auf einen Blick</h1>
                                    <p className="mb-4">
                                        Allgemeine Hinweise: Die folgenden Hinweise geben einen einfachen Überblick
                                        darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
                                        besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
                                        identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz
                                        entnehmen Sie unserer Datenschutzerklärung.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Datenerfassung auf dieser Webseite</h2>
                                    <p className="mb-4">
                                        Wer ist verantwortlich für die Datenerfassung auf dieser Website? Die
                                        Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
                                        Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in
                                        dieser Datenschutzerklärung entnehmen.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Wie erfassen wir Ihre Daten?</h2>
                                    <p className="mb-4">
                                        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
                                        Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular
                                        eingeben. Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
                                        Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
                                        technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
                                        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
                                        Website betreten.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Wofür nutzen wir Ihre Daten?</h2>
                                    <p className="mb-4">
                                        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website
                                        zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens
                                        verwendet werden.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Welche Rechte haben Sie bezüglich Ihrer
                                        Daten?</h2>
                                    <p className="mb-4">
                                        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger
                                        und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben
                                        außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
                                        Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese
                                        Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
                                        unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
                                        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
                                        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu
                                        weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Allgemeine Hinweise und
                                        Pflichtinformationen</h2>
                                    <p className="mb-4">
                                        Datenschutz: Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
                                        Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                                        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                                        Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene
                                        personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
                                        persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
                                        erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert
                                        auch, wie und zu welchem Zweck das geschieht.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Hinweis zur verantwortlichen Stelle</h2>
                                    <p className="mb-4">
                                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                                    </p>
                                    <p>
                                        Marvin Cordes<br/>
                                        Wormser Str. 36<br/>
                                        76185 Karlsruhe<br/>
                                        Telefon: +49 721 4807690<br/>
                                        E-Mail: mcordes@cordes-hosting.de<br/>
                                        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein
                                        oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
                                        personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Speicherdauer</h2>
                                    <p className="mb-4">
                                        Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer
                                        genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck
                                        für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen
                                        geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden
                                        Ihre Daten gelöscht, sofern wir keinen anderen rechtlich zulässigen Gründe für
                                        die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder
                                        handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
                                        Löschung nach Fortfall dieser Gründe.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Widerruf Ihrer Einwilligung zur
                                        Datenverarbeitung</h2>
                                    <p className="mb-4">
                                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung
                                        möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die
                                        Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                                        Widerruf unberührt.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Widerspruchsrecht gegen die Datenerhebung
                                        in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h2>
                                    <p className="mb-4">
                                        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
                                        ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER
                                        BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
                                        DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
                                        GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG
                                        BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
                                        EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
                                        VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
                                        VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
                                        ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                                        RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                                    </p>
                                    <p className="mb-4">
                                        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
                                        SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
                                        BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN;
                                        DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
                                        VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
                                        ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                                        NACH ART. 21 ABS. 2 DSGVO).
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Beschwerderecht bei der zuständigen
                                        Aufsichtsbehörde</h2>
                                    <p className="mb-4">
                                        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht
                                        bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
                                        Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu.
                                        Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
                                        oder gerichtlicher Rechtsbehelfe.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Recht auf Datenübertragbarkeit</h2>
                                    <p className="mb-4">
                                        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
                                        Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
                                        Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
                                        Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                                        verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">SSL- bzw. TLS-Verschlüsselung</h2>
                                    <p className="mb-4">
                                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
                                        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an
                                        uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine
                                        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
                                        von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer
                                        Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                                        Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Verschlüsselter Zahlungsverkehr auf
                                        dieser Website</h2>
                                    <p className="mb-4">
                                        Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung,
                                        uns Ihre Zahlungsdaten (z.B. Kontonummer bei Einzugsermächtigung) zu
                                        übermitteln, werden diese Daten zur Zahlungsabwicklung benötigt.
                                        <br/>
                                        Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard,
                                        Lastschriftverfahren) erfolgt ausschließlich über eine verschlüsselte SSL- bzw.
                                        TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die
                                        Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem
                                        Schloss-Symbol in Ihrer Browserzeile.
                                        <br/>
                                        Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns
                                        übermitteln, nicht von Dritten mitgelesen werden.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Auskunft, Löschung und Berichtigung</h2>
                                    <p className="mb-4">
                                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht
                                        auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten,
                                        deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein
                                        Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
                                        Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Recht auf Einschränkung der
                                        Verarbeitung</h2>
                                    <p className="mb-4">
                                        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
                                        Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht
                                        auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
                                        <br/>
                                        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
                                        bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die
                                        Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
                                        personenbezogenen Daten zu verlangen.
                                        <br/>
                                        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                                        geschah/geschieht, können Sie statt der Löschung die Einschränkung der
                                        Datenverarbeitung verlangen.
                                        <br/>
                                        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
                                        Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch
                                        nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die
                                        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                        <br/>
                                        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben,
                                        dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
                                        Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
                                        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                                        juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der
                                        Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Widerspruch gegen Werbe-E-Mails</h2>
                                    <p className="mb-4">
                                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten
                                        zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                                        Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten
                                        behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                                        Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                                    </p>
                                    <h2 className="text-xl font-semibold mb-2">Datenerfassung auf dieser Website</h2>
                                    <h3 className="text-lg font-semibold mb-2">Cookies</h3>
                                    <p className="mb-4">
                                        Unsere Internetseiten verwenden sogenannte „Cookies“. Cookies sind kleine
                                        Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden
                                        entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder
                                        dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies
                                        werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben
                                        auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine
                                        automatische Löschung durch Ihren Webbrowser erfolgt.
                                        {/* ... Fortsetzung des Textes ... */}
                                    </p>
                                    <h3 className="text-lg font-semibold mb-2">Server-Log-Dateien</h3>
                                    <p className="mb-4">
                                        Der Provider der Seiten erhebt und speichert automatisch Informationen in
                                        sogenannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt.
                                        Dies sind:
                                        {/* ... Fortsetzung des Textes ... */}
                                    </p>
                                    <h3 className="text-lg font-semibold mb-2">Kontaktformular</h3>
                                    <p className="mb-4">
                                        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben
                                        aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten
                                        zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns
                                        gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                                    </p>
                                    <p className="mb-4">
                                        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
                                        DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder
                                        zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen
                                        Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                                        effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                                        DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), sofern diese
                                        abgefragt wurde.
                                    </p>
                                    <p className="mb-4">
                                        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie
                                        uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
                                        der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener
                                        Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
                                        Aufbewahrungsfristen – bleiben unberührt.
                                    </p>
                                    <h3 className="text-lg font-semibold mb-2">Anfrage per E-Mail, Telefon oder
                                        Telefax</h3>
                                    <p className="mb-4">
                                        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage
                                        inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage)
                                        zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                                        Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                                    </p>
                                    <p className="mb-4">
                                        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
                                        DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder
                                        zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen
                                        Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                                        effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                                        DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), sofern diese
                                        abgefragt wurde.
                                    </p>
                                    <p className="mb-4">
                                        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns,
                                        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
                                        widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
                                        abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
                                        – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                                    </p>
                                    <h3 className="text-lg font-semibold mb-2">Registrierung auf dieser Website</h3>
                                    <p className="mb-4">
                                        Sie können sich auf dieser Website registrieren, um zusätzliche Funktionen auf
                                        der Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke
                                        der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert
                                        haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig
                                        angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.
                                    </p>
                                    <p className="mb-4">
                                        Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen
                                        Änderungen nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um
                                        Sie auf diesem Wege zu informieren.
                                    </p>
                                    <p className="mb-4">
                                        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke
                                        der Durchführung des durch die Registrierung begründeten Nutzungsverhältnisses
                                        und ggf. zur Anbahnung weiterer Verträge (Art. 6 Abs. 1 lit. b DSGVO).
                                    </p>
                                    <p className="mb-4">
                                        Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange
                                        Sie auf dieser Website registriert sind und werden anschließend gelöscht.
                                        Gesetzliche Aufbewahrungsfristen bleiben unberührt.
                                    </p>
                                    <h3 className="text-lg font-semibold mb-2">Newsletter und Postwerbung</h3>
                                    <p className="mb-4">
                                        <strong>Newsletterdaten</strong>
                                        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen
                                        wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die
                                        Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind
                                        und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden
                                        nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
                                        ausschließlich für den Versand der angeforderten Informationen und geben diese
                                        nicht an Dritte weiter.
                                    </p>
                                    <p className="mb-4">
                                        Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt
                                        ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
                                        Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie
                                        deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa
                                        über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
                                        erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                                    </p>
                                    <p className="mb-4">
                                        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden
                                        von uns bis zu Ihrer Austragung aus dem Newsletter, bei uns bzw. dem
                                        Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters
                                        oder nach Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir behalten
                                        uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen
                                        im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu
                                        löschen oder zu sperren.
                                    </p>
                                    <p className="mb-4">
                                        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse
                                        bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert,
                                        um künftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur für
                                        diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient
                                        sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der
                                        gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im
                                        Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist
                                        zeitlich nicht befristet.
                                    </p>
                                    <p className="mb-4">
                                        Sie können der Speicherung widersprechen, sofern Ihre Interessen unser
                                        berechtigtes Interesse überwiegen.
                                    </p>
                                    <p className="mb-4">
                                        <strong>Postwerbung</strong>
                                        Wir verwenden Ihre Anschrift unter Einhaltung aller rechtlichen Bestimmungen für
                                        den Versand von postalischer Werbung (Postwerbung).
                                    </p>
                                    <p className="mb-4">
                                        Rechtsgrundlage hierfür ist unser berechtigtes Interesse an Direktwerbung nach
                                        Art. 6 Abs. 1 S. 1 lit. f in Verbindung mit Erwägungsgrund 47 DSGVO. Sofern eine
                                        entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                                        ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung
                                        ist jederzeit widerrufbar. Speziellere Regelungen können ihnen ggf. im Rahmen
                                        der Datenerhebung mitgeteilt werden und gehen der vorliegenden Regelung vor.
                                    </p>
                                    <p className="mb-4">
                                        Ihre Anschrift verbleibt bei uns, bis der Zweck für die Datenverarbeitung
                                        entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder Ihre
                                        Einwilligung zur Postwerbung widerrufen, werden Ihre Daten gelöscht, sofern wir
                                        keinen anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
                                        personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
                                        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall
                                        dieser Gründe.
                                    </p>
                                    <p className="mb-4">
                                        Wir setzen folgenden Dienstleister für den Versand unserer Postmailings ein:
                                    </p>
                                    <p>
                                        Marvin Cordes<br/>
                                        Wormser Str. 36<br/>
                                        76185 Karlsruhe
                                    </p>
                                    <h1 className="text-2xl font-bold mb-4">Datenschutzerklärung</h1>

                                    {/* Abschnitt 5: Plugins und Tools */}
                                    <h2 className="text-xl font-semibold mt-4">5. Plugins und Tools</h2>
                                    <p>
                                        Font Awesome (lokales Hosting)
                                        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font Awesome.
                                        Font Awesome ist lokal installiert. Eine Verbindung zu Servern von Fonticons,
                                        Inc. findet dabei nicht statt.
                                        Weitere Informationen zu Font Awesome finden Sie in der Datenschutzerklärung für
                                        Font Awesome unter:
                                        <a href="https://fontawesome.com/privacy" className="text-blue-500"
                                           target="_blank" rel="noopener noreferrer">https://fontawesome.com/privacy</a>
                                    </p>


                                    <h2 className="text-xl font-semibold mt-4">6. eCommerce und Zahlungsanbieter</h2>
                                    <p>
                                        Verarbeiten von Daten (Kunden- und Vertragsdaten)
                                        Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für
                                        die Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses
                                        erforderlich sind (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1
                                        lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
                                        vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die
                                        Inanspruchnahme dieser Website (Nutzungsdaten) erheben, verarbeiten und nutzen
                                        wir nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des
                                        Dienstes zu ermöglichen oder abzurechnen.
                                        Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der
                                        Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
                                    </p>
                                    <p>
                                        Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte
                                        Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen
                                        der Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung
                                        beauftragte Kreditinstitut.
                                        Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie
                                        der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an
                                        Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt
                                        nicht.
                                        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die
                                        Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
                                        Maßnahmen gestattet.
                                    </p>
                                    <p>
                                        Zahlungsdienste
                                        Wir binden Zahlungsdienste von Drittunternehmen auf unserer Website ein. Wenn
                                        Sie einen Kauf bei uns tätigen, werden Ihre Zahlungsdaten (z.B. Name,
                                        Zahlungssumme, Kontoverbindung, Kreditkartennummer) vom Zahlungsdienstleister
                                        zum Zwecke der Zahlungsabwicklung verarbeitet. Für diese Transaktionen gelten
                                        die jeweiligen Vertrags- und Datenschutzbestimmungen der jeweiligen Anbieter.
                                        Der Einsatz der Zahlungsdienstleister erfolgt auf Grundlage von Art. 6 Abs. 1
                                        lit. b DSGVO (Vertragsabwicklung) sowie im Interesse eines möglichst
                                        reibungslosen, komfortablen und sicheren Zahlungsvorgangs (Art. 6 Abs. 1 lit. f
                                        DSGVO). Soweit für bestimmte Handlungen Ihre Einwilligung abgefragt wird, ist
                                        Art. 6 Abs. 1 lit. a DSGVO Rechtsgrundlage der Datenverarbeitung; Einwilligungen
                                        sind jederzeit für die Zukunft widerrufbar.
                                    </p>
                                    <p>
                                        Folgende Zahlungsdienste / Zahlungsdienstleister setzen wir im Rahmen dieser
                                        Website ein:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            PayPal<br/>
                                            Anbieter dieses Zahlungsdienstes ist PayPal (Europe) S.à.r.l. et Cie,
                                            S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (im Folgenden „PayPal“).
                                            Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
                                            EU-Kommission gestützt. Details finden Sie hier:
                                            <br/>
                                            <a href="https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full"
                                               className="text-blue-500" target="_blank"
                                               rel="noopener noreferrer">https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full</a>
                                            <br/>
                                            Details entnehmen Sie der Datenschutzerklärung von PayPal:
                                            <br/>
                                            <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
                                               className="text-blue-500" target="_blank"
                                               rel="noopener noreferrer">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>
                                        </li>
                                        <li>
                                            Klarna<br/>
                                            Anbieter ist die Klarna AB, Sveavägen 46, 111 34 Stockholm, Schweden (im
                                            Folgenden „Klarna“). Klarna bietet verschiedene Zahlungsoptionen an (z.B.
                                            Ratenkauf). Wenn Sie sich für die Bezahlung mit Klarna entscheiden
                                            (Klarna-Checkout-Lösung), wird Klarna verschiedene personenbezogene Daten
                                            von Ihnen erheben. Klarna nutzt Cookies, um die Verwendung der
                                            Klarna-Checkout-Lösung zu optimieren. Details zum Einsatz von Klarna-Cookies
                                            entnehmen Sie folgendem Link:
                                            <br/>
                                            <a href="https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf"
                                               className="text-blue-500" target="_blank"
                                               rel="noopener noreferrer">https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf</a>
                                            <br/>
                                            Details hierzu können Sie in der Datenschutzerklärung von Klarna unter
                                            folgendem Link nachlesen:
                                            <br/>
                                            <a href="https://www.klarna.com/de/datenschutz/" className="text-blue-500"
                                               target="_blank"
                                               rel="noopener noreferrer">https://www.klarna.com/de/datenschutz/</a>
                                        </li>
                                    </ul>
                                    <h2 className="text-xl font-semibold mt-4">7. Eigene Dienste</h2>
                                    <p>
                                        Umgang mit Bewerberdaten
                                        Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z.B. per E-Mail,
                                        postalisch oder via Online-Bewerberformular). Im Folgenden informieren wir Sie
                                        über Umfang, Zweck und Verwendung Ihrer im Rahmen des Bewerbungsprozesses
                                        erhobenen personenbezogenen Daten. Wir versichern, dass die Erhebung,
                                        Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit geltendem
                                        Datenschutzrecht und allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre
                                        Daten streng vertraulich behandelt werden.
                                        Umfang und Zweck der Datenerhebung
                                        Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit
                                        verbundenen personenbezogenen Daten (z.B. Kontakt- und Kommunikationsdaten,
                                        Bewerbungsunterlagen, Notizen im Rahmen von Bewerbungsgesprächen etc.), soweit
                                        dies zur Entscheidung über die Begründung eines Beschäftigungsverhältnisses
                                        erforderlich ist. Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht
                                        (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b DSGVO
                                        (allgemeine Vertragsanbahnung) und – sofern Sie eine Einwilligung erteilt haben
                                        – Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit widerrufbar. Ihre
                                        personenbezogenen Daten werden innerhalb unseres Unternehmens ausschließlich an
                                        Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt sind.
                                        Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten
                                        auf Grundlage von § 26 BDSG-neu und Art. 6 Abs. 1 lit. b DSGVO zum Zwecke der
                                        Durchführung des Beschäftigungsverhältnisses in unseren
                                        Datenverarbeitungssystemen gespeichert.
                                    </p>
                                    <p>
                                        Aufbewahrungsdauer der Daten
                                        Sofern wir Ihnen kein Stellenangebot machen können, Sie ein Stellenangebot
                                        ablehnen oder Ihre Bewerbung zurückziehen, behalten wir uns das Recht vor, die
                                        von Ihnen übermittelten Daten auf Grundlage unserer berechtigten Interessen
                                        (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung des
                                        Bewerbungsverfahrens (Ablehnung oder Zurückziehung der Bewerbung) bei uns
                                        aufzubewahren. Anschließend werden die Daten gelöscht und die physischen
                                        Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere
                                        Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die
                                        Daten nach Ablauf der 6-Monatsfrist erforderlich sein werden (z.B. aufgrund
                                        eines drohenden oder anhängigen Rechtsstreits), findet eine Löschung erst statt,
                                        wenn der Zweck für die weitergehende Aufbewahrung entfällt.
                                        Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine entsprechende
                                        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben oder wenn gesetzliche
                                        Aufbewahrungspflichten der Löschung entgegenstehen.
                                    </p>
                                    <p>
                                        Aufnahme in den Bewerber-Pool
                                        Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die Möglichkeit, Sie
                                        in unseren Bewerber-Pool aufzunehmen. Im Falle der Aufnahme werden alle
                                        Dokumente und Angaben aus der Bewerbung in den Bewerber-Pool übernommen, um Sie
                                        im Falle von passenden Vakanzen zu kontaktieren.
                                        Die Aufnahme in den Bewerber-Pool geschieht ausschließlich auf Grundlage Ihrer
                                        ausdrücklichen Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die Abgabe der
                                        Einwilligung ist freiwillig und steht in keinem Bezug zum laufenden
                                        Bewerbungsverfahren.
                                        Der Betroffene kann seine Einwilligung jederzeit widerrufen.
                                        In diesem Falle werden die Daten aus dem Bewerber-Pool unwiderruflich gelöscht,
                                        sofern keine gesetzlichen Aufbewahrungsgründe vorliegen.
                                        Die Daten aus dem Bewerber-Pool werden spätestens zwei Jahre nach Erteilung der
                                        Einwilligung unwiderruflich gelöscht.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* Modal für Impressum */}
                {isImprintOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div
                            className="bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 p-6 rounded-lg overflow-y-auto max-h-screen">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-semibold">Impressum</h2>
                                <button onClick={() => setIsImprintOpen(false)}>
                                    <FontAwesomeIcon icon={faTimes} className="text-gray-700 hover:text-gray-900"/>
                                </button>
                            </div>
                            <div className="text-gray-700 space-y-4">
                                {/* Hier ist der Inhalt des Impressums */}
                                <h3 className="text-lg font-semibold">Unternehmensinformationen</h3>
                                <p>
                                    <strong>Firmenname:</strong> Cordes-Hosting e.U<br/>
                                    <strong>Adresse:</strong> Wormser Str. 36, 76185 Karlsruhe<br/>
                                    <strong>Geschäftsführer:</strong> Marvin Cordes<br/>
                                    <strong>Telefon:</strong> +49 721 4807690<br/>
                                    <strong>Email:</strong> <a href="mailto:mcordes@cordes-hosting.net"
                                                               className="text-blue-600 underline">mcordes@cordes-hosting.net</a>
                                </p>
                                <h3 className="text-lg font-semibold">Registrierung</h3>
                                <p>
                                    <strong>Handelsregister:</strong> Amtsgericht Karlsruhe<br/>
                                    <strong>Steuer-ID:</strong> 35060/11149<br/>
                                    <strong>USt-ID:</strong> DE351777197
                                </p>
                                <h3 className="text-lg font-semibold">Verantwortlich für den Inhalt</h3>
                                <p>
                                    <strong>Person:</strong> Steven Dautrich<br/>
                                    <strong>Adresse:</strong> Vogesenstr. 16, 79206 Breisach am Rhein<br/>
                                    <strong>Telefon:</strong> +49 721 4807690<br/>
                                    <strong>Email:</strong> <a href="mailto:sdautrich@cordes-hosting.net"
                                                               className="text-blue-600 underline">sdautrich@cordes-hosting.net</a>
                                </p>
                                <h3 className="text-lg font-semibold">Haftung für Inhalte</h3>
                                <p>
                                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                                    Seiten nach den allgemeinen Gesetzen verantwortlich.
                                    Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                                    übermittelte oder gespeicherte fremde Informationen zu überwachen
                                    oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                                </p>
                                <p>
                                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                                    allgemeinen Gesetzen bleiben hiervon unberührt.
                                    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                                    konkreten Rechtsverletzung möglich.
                                    Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                                    umgehend entfernen.
                                </p>
                                <h3 className="text-lg font-semibold">Haftung für Links</h3>
                                <p>
                                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
                                    keinen Einfluss haben.
                                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                                    Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                                    Betreiber der Seiten verantwortlich.
                                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                                    Rechtsverstöße überprüft.
                                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                                </p>
                                <p>
                                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                                    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                                    entfernen.
                                </p>
                                <h3 className="text-lg font-semibold">Urheberrecht</h3>
                                <p>
                                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                                    unterliegen dem deutschen Urheberrecht.
                                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                                    der Grenzen des Urheberrechtes
                                    bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                                    Gebrauch gestattet.
                                </p>
                                <p>
                                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                                    Urheberrechte Dritter beachtet.
                                    Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                                    Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir
                                    um einen entsprechenden Hinweis.
                                    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
                                    entfernen.
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </footer>
        </div>
    );
}
