import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));

document.title = "MatchDuck - Dating ohne Abzocke"
root.render(

  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
