import {createBrowserRouter} from "react-router-dom";
import Layout from "./components/layout";
import HomeHero from "./routecomponents/home/mainhero";
import AboutUs from "./routecomponents/home/aboutus";
import MatchduckApp from "./routecomponents/home/matchduckapp";
import ReleaseDate from "./routecomponents/home/releasedate";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                element: (
                    <>
                        <HomeHero/>
                        <AboutUs/>
                        <MatchduckApp/>
                        <ReleaseDate/>
                    </>
                )
            },
        ],
    },
])